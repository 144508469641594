<template>
  <div class="poster_card pointer" :class="{ selected: detailOpened }">
    <div
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <Icon
        v-if="detailOpened"
        class="poster_card__poster_selected"
        icon="ui/poster_selected"
      />

      <video
        v-if="isVideoVisible"
        class="poster_card__video"
        autoplay
        @error="putErrorImage"
      >
        <source
          :src="vampireVideo"
          type="video/mp4"
        />
      </video>

      <div
        v-if="!isVideoVisible"
        class="poster_card__poster"
      >
        <div class="poster_card__vendor_pin">
          <Icon
            v-if="content.vendor"
            :icon="`img/pin_${content.vendor}`"
            :is-local="false"
            :alt="content.vendor"
          />
          <Icon
            v-if="content.time_start && isFuture(fromUnixTime(content.time_start))"
            :icon="`daysLeft/pin_soon`"
          />
        </div>

        <a :href="generateUrl(content)" class="linllll">
          <img
            :src="posterImage"
            :alt="content.name || content.title"
            @error="putErrorImage"
          />
        </a>

        <div class="poster_card__lock_pin">
          <div
            v-if="isLockedTariffOptions"
            class="poster_card__pin_wrapper"
          >
            <Icon icon="pins/pin_lock" />
          </div>

          <div
            v-if="rating"
            class="poster_card__pin_wrapper"
          >
            <div class="flex">
              <div class="poster_card__margin_text flex">
                <Icon icon="pins/pin_star" />
              </div>
              <div>{{ rating }}</div>
            </div>
          </div>

          <div
            v-if="duration && !content.is_serial"
            class="poster_card__pin_wrapper"
          >
            <div class="flex">
              <div class="poster_card__margin_text flex">
                <Icon icon="pins/pin_watch" />
              </div>
              <div>{{ duration }} {{ numWord(duration, minutesCount) }}</div>
            </div>
          </div>

          <div
            v-if="content.seasons_count"
            class="poster_card__pin_wrapper"
          >
            <div class="flex">
              <div class="poster_card__margin_text flex">
                <Icon icon="pins/pin_stack" />
              </div>
              <div>{{ seriesInfo }}</div>
            </div>
          </div>

          <div
            v-if="favPoster && content.serial_title"
            class="poster_card__pin_wrapper"
            :style="{ backgroundImage: favProcess ? `linear-gradient(to right, rgba(239, 52, 29, 1) ${favProcess}%, rgba(48, 48, 48, 1) ${favProcess}%` : '' }"
          >
            <div class="flex">
              <div class="poster_card__margin_text flex">
                <Icon icon="pins/pin_stack" />
              </div>
              <div class="poster_card__fav_info">{{ favInfo }} </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="content.is_serial || content.seasons_count || content.series_count">
        <div class="poster_card__frame_serial1" />
        <div class="poster_card__frame_serial2" />
      </div>
    </div>
  </div>
</template>

<script setup>
// packages
import { computed, shallowRef } from 'vue';
import { storeToRefs } from 'pinia';
import { isFuture, fromUnixTime } from 'date-fns';
// stores, utils
import { useUserStore } from '@/store/user';
import { usePosterDetailsStore } from '@/store/posterDetail';
// components
import Icon from '@/components/common/icons/icon.vue';

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  collectionId: [Number, String],
  defaultImage: {
    type: String,
    default: '/img/default/poster_218_324/default.jpg'
  }
});

// plain vars
const rating = Number(props.content.rating_kinopoisk || 0).toFixed(1);
const isProgram = props.content.fav_type === 'program';
const duration =
  isProgram
    ? Math.round((props.content.time_end - props.content.time_start) / 60)
    : Math.floor(props.content.duration / 60);

const minutesCount = ['минута', 'минуты', 'минут'];
const seasonsCount = ['сезон', 'сезона', 'сезонов'];
const seriesCount = ['серия', 'серии', 'серий'];

const seriesInfo = computed(() => {
  const { content } = props;
  if (content.seasons_count === 1) {
    return `${content.series_count} ${numWord(content.series_count, seriesCount)}`;
  }
  return `${content.seasons_count} ${numWord(content.seasons_count, seasonsCount)}`;
});

// for favorite posters
const posterImage = shallowRef(props.content.images.poster_218_324);
const favPoster = shallowRef(props.collectionId === 'favoritesList');
const favInfo = shallowRef(null);
const favProcess = shallowRef(0);

if (favPoster.value) await getFavInfo();
async function getFavInfo() {
  const { content } = props;
  if (!content.serial_title) return null;
  if (favPoster.value) {
    const parent = await fetchVodFullInfo(content.film_id);
    const vodFull = await fetchVodFullInfo(parent.parent);
    posterImage.value = vodFull.images.poster_218_324;

    let currentEpisodeIndex = 0;
    let totalEpisodes = 0;

    for (const season of vodFull.seasons) {
      totalEpisodes += season.episodes.length;
      for (const episode of season.episodes) {
        currentEpisodeIndex++;
        if (episode.id === content.film_id) {
          // переносим цифру в начало
          const _season = /^\d/.test(season.title) ? season.title : season.title.split(' ').reverse().join(' ').toLowerCase();
          const _episode = /^\d/.test(episode.title) ? episode.title : episode.title.split(' ').reverse().join(' ').toLowerCase();
          favInfo.value = `${_season}. ${_episode}`;
          break;
        }
      }
    }
    favProcess.value = currentEpisodeIndex / totalEpisodes * 100;
  }
}

// reactive
const { detailsOpen } = storeToRefs(usePosterDetailsStore());
const { user } = storeToRefs(useUserStore());
const channelsStore = useChannelsListStore();
const isLockedTariffOptions = computed(() => {
  if (isProgram) return channelsStore.currentChannel(props.content.channel_id)?.locked;
  return user.value?.tariff_options?.indexOf(props.content.vendor) === -1;
});
const isLockedPublicService = computed(() => user.value?.public_services?.indexOf(props.content.vendor) === -1);
const dontShow = computed(() => isLockedTariffOptions.value && isLockedPublicService.value);
// если vendor отсутствует в tariff_options и public_services, то вообще рисоваться не должен
// if (dontShow.value) console.log(`Не рисовать постер с ID ${props.content.id}: ${props.content.title}`);
// const detailOpened = shallowRef(false);
const detailOpened = computed(()=>{
  const dv = detailsOpen.value;
  const posterId = props.content.film_id || props.content.id || props.content.channel_id;
  const did = props.collectionId + '_' + posterId;
  return dv === did; // v1: открыты детали только одного постера
  // return dv?.has(did) || false; // v2: сохраняем список открытых постеров
});

// methods
function generateUrl(content) {
  if (content.channel_id) return `/channel/${content.channel_id}/${content.time_start}`;

  const vodId = content.id || content.film_id;
  return `/vod/${vodId}`;
}

function putErrorImage(event) {
  posterImage.value = props.defaultImage;
}

function numWord(value, words) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
}

// for fun: insert a video instead of an image
const vampireId = 328422;
const vampireVideo = 'http://images.ek29-test-billing1.dev.moovi-iptv.ru/cinema/video/328422.mp4';
let videoTimer = null;
let posterTimer = null;
const isVideoVisible = shallowRef(false);

const handleMouseEnter = () => {
  if (props.content.id !== vampireId) return null;

  // таймер для вставки видео через 2 секунды
  videoTimer = setTimeout(() => {
    insertVideo();

    // таймер для возврата постера через 5 секунд
    posterTimer = setTimeout(() => {
      insertPoster();

      // таймер для повторной вставки видео через 5 секунд
      videoTimer = setTimeout(insertVideo, 5000);
    }, 5000);
  }, 2000);
};

const handleMouseLeave = () => {
  if (props.content.id !== vampireId) return null;
  // Очищаем все таймеры при уходе мыши
  clearTimeout(videoTimer);
  clearTimeout(posterTimer);
  removeVideo(); // и удаляем видео если оно было вставлено
};

const insertVideo = () => {
  isVideoVisible.value = true;
  console.log('Вставляем видео');
};

const insertPoster = () => {
  isVideoVisible.value = false;
  console.log('Вставляем постер');
};

const removeVideo = () => {
  insertPoster();
  console.log('Удаляем видео');
};

const checkImageAvailability = () => {
  const img = new Image();
  img.onload = () => {};
  img.onerror = () => {
    posterImage.value = props.defaultImage;
  };
  img.src = props.content.images.poster_218_324;
}

onMounted(() => {
  detailsOpen.value = new Set();
  checkImageAvailability();
});
</script>
