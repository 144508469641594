<template>
  <slot :content-clicked="detailed"></slot>
  <Transition name="poster-fade">
    <div
      v-if="content"
      ref="scrollToPosterInfo"
    >
      <PosterInfo
        v-if="isDetailsOpen"
        :poster="content"
      />
    </div>
  </Transition>
</template>

<script setup>
import PosterInfo from '@/components/ui/Posters/PosterInfo.vue';

// reactive
const content = shallowRef(null);
const { detailsOpen } = storeToRefs(usePosterDetailsStore());
const scrollToPosterInfo = shallowRef(null);

function getContentId(c) {
  return c?.film_id || c?.id || c?.channel_id;
}

const isDetailsOpen = computed(() => {
  if (!content.value?.did) {
    return false;
  }
  return detailsOpen.value === content.value.did; // v1
  // return detailsOpen.value?.has(content.value.did) || false; // v2
});

// v2: сохраняем список открытых постеров
function detailed2(_content, _collectionId) {
  const id = getContentId(_content);
  const did = _collectionId ? `${_collectionId}_${id}` : id;

  if (detailsOpen.value) {
    if (detailsOpen.value.has(did)) {
      detailsOpen.value.delete(did);
      content.value = null;
      triggerRef(detailsOpen);
      return;
    }
    if (content.value?.did) {
      detailsOpen.value.delete(content.value?.did);
    }

    detailsOpen.value.add(did);
    triggerRef(detailsOpen);
  }
  
  content.value = { ..._content, did };
}

// v1: открыты детали только одного постера
function detailed(_content, _collectionId) {
  const id = getContentId(_content);
  const did = _collectionId ? `${_collectionId}_${id}` : id;

  if (detailsOpen?.value === did && content.value?.did === did) {
    detailsOpen.value = null;
    content.value = null;
    return;
  }

  detailsOpen.value = did;
  content.value = { ..._content, did };
}

watch(scrollToPosterInfo, (value) => {
  if (value) {
    window.scrollTo({
      top: scrollToPosterInfo.value.offsetTop - scrollToPosterInfo.value.offsetHeight,
      behavior: 'smooth',
    });
  }
});
</script>
